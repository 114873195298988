import * as filterApi from '../../api/filter';
import * as filterActions from './filterSlice';
import * as homeActions from '../../features/homepage/actions';
import * as youtubeActions from '../../features/youtubePage/actions';
import queryString from 'query-string';
import { isArray, mergeWith } from 'lodash';

const getFilterData = pageSlug => {
  return dispatch => {
    dispatch(homeActions.getPageRequest());
    return new Promise((resolve, reject) => {
      filterApi
        .getFilterData(pageSlug)
        .then(response => {
          dispatch(filterActions.setFilterData(response));
          resolve({
            ...response,
            pageSlug,
          });
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

const setActiveIDs = activeIDs => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(filterActions.setActiveIDs(activeIDs));
      resolve(activeIDs);
    });
  };
};

const resetActiveIDs = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(filterActions.resetActiveIDs());
      resolve();
    });
  };
};

const getPage = (params = {}) => {
  return dispatch => {
    dispatch(homeActions.getPageRequest());
    return new Promise((resolve, reject) => {
      const promises = [
        new Promise((resolve, reject) => {
          filterApi
            .getRibbons(params)
            .then(response => {
              resolve({ ...response, slug: params?.slug ?? params?.id });
            })

            .catch(error => {
              reject(error);
            });
        }),

        new Promise((resolve, reject) => {
          filterApi
            .getPersonalizedRibbons(params)
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        }),
      ];
      return Promise.all(promises)
        .then(data => {
          const [staticPage, personalizePage = {}] = data;
          const pageMerged = mergeWith(personalizePage, staticPage, (objValue, srcValue) => {
            if (isArray(objValue)) {
              return objValue.concat(srcValue);
            }
          });

          const queryParams = queryString.stringify(params);

          pageMerged.search = queryParams;

          dispatch(homeActions.updateSlugPage(params.slug));
          if (pageMerged.display_style === 'YOUTUBE') {
            dispatch(youtubeActions.updatePageYoutube(pageMerged));
          } else {
            dispatch(
              homeActions.getPage({
                ...pageMerged,
                requestParams: params,
              }),
            );
          }
          resolve(pageMerged);
        })

        .catch(error => {
          let param = {
            banners: [],
            ribbons: [],
            search: '',
          };
          dispatch(homeActions.getPage(param));
          dispatch(youtubeActions.updatePageYoutube(param));
          dispatch(homeActions.getFinal());
          reject(error);
        })

        .finally(() => {
          dispatch(homeActions.getFinal());
        });
    });
  };
};

const updateSearch = (params = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const queryParams = queryString.stringify(params);
      // dispatch(homeActions.updateSeach(queryParams))
      dispatch(youtubeActions.updateSeach(queryParams));
      resolve();
    });
  };
};

export { getFilterData, setActiveIDs, getPage, resetActiveIDs, updateSearch };

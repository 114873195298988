import FETCH from '../utils/security/fetch';
import * as APIString from '../constants/apiString';
import { store } from '@/store/store';
import queryString, { parseUrl } from 'query-string';
import { getDeviceResolution } from '@/shared/utils/utils';

const getFilterData = pageSlug => {
  const select = JSON.stringify({
    PageContentFilter: ['regions', 'genres', 'languages'],
  });
  const queryParams = queryString.stringify({ select });

  const path = `/tenants/${APIString.TENANT_SLUG}/tenant_pages/${pageSlug}/content_filter?${queryParams}`;
  return FETCH(path, 'GET', {});
};

export const CONTENT_RIBBON_SELECT_FIELDS = [
  'id',
  'slug',
  'has_free_content',
  'is_new_release',
  'is_premium',
  'has_free_content',
  'content_categories',
  'total_episodes',
  'released_episode_count',
  'images',
  'title',
  'video_source',
  'type',
  'top_index',
  'min_sub_tier',
  'metadata',
  'start_on',
  'schedule_publish',
  'unpublished_time',
  'duration',
  'badges',
  'release_date',
  'payment_type',
];

export const CONTENT_RIBBON_BANNER_SELECT_FIELDS = [
  'num_first_episode_preview',
  'slug',
  'id',
  'is_premium',
  'type',
  'is_watchable',
  'has_free_content',
  'long_description',
  'short_description',
  'title',
  'has_free_content',
  'images',
  'min_sub_tier',
  'metadata',
  'badges',
];

export const CONTENT_RIBBON_DETAIL_SELECT_FIELDS = [
  'display_type',
  'id',
  'items',
  'name',
  'odr',
  'show_flag_odr',
  'slug',
  'type',
  'is_visible_in_ribbon_main_section',
  'is_default_display',
  'min_sub_tier',
  'badges',
];

const getRibbons = (params = {}) => {
  let Authorization = '';
  const select = JSON.stringify({
    Content: CONTENT_RIBBON_SELECT_FIELDS,
    Banner: CONTENT_RIBBON_BANNER_SELECT_FIELDS,
    RibbonDetail: CONTENT_RIBBON_DETAIL_SELECT_FIELDS,
  });
  const { id, slug, ...paramsRest } = params;
  let data = { ...paramsRest, ...getDeviceResolution() };
  const queryParams = queryString.stringify({ ...data, select });
  const path = `/tenants/${APIString.TENANT_SLUG}/tenant_pages/${slug}/ribbons/?${queryParams}`;
  return FETCH(path, 'GET', {
    Authorization,
  });
};

const getPersonalizedRibbons = (params = {}) => {
  const select = JSON.stringify({
    PageDetail: ['ribbons', 'display_style', 'seo', 'external_link'],
    Content: CONTENT_RIBBON_SELECT_FIELDS,
  });
  const { id, slug, ...paramsRest } = params;
  let data = { ...paramsRest, ...getDeviceResolution() };
  const queryParams = queryString.stringify({ ...data, select });
  const { account } = store.getState().auth;
  if (!account) {
    return Promise.resolve({});
  }
  const accountId = account.profile.id;
  let Authorization = '';
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/tenant_pages/${id}/personalized_ribbons?${queryParams}`;
  return FETCH(path, 'GET', {
    Authorization,
  });
};

export { getFilterData, getRibbons, getPersonalizedRibbons };
